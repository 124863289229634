import * as CONS from '@/constants';
import data from '~/assets/data/timeTables.json';

export default {
  name: 'field-group',
  components: {},
  props: {
    id: {
      type: String,
      required: false,
      default: 'default',
    },
    area: {
      type: String,
      required: false,
    },
  },
  data () {
    return {
      CONS,
      uid: '',
    };
  },
  mounted () {
    this.uid = this._uid;
  },
  computed: {
    labelId () {
      return `time-table-box-label-${this.uid}`;
    },
    rows () {
      const timetable = data.items.find(item => item.id === this.id);
      return timetable ? timetable.rows : [];
    },
  },
  methods: {},
};
