import { render, staticRenderFns } from "./index.vue?vue&type=template&id=516fb150&scoped=true&"
import script from "./script.js?vue&type=script&lang=js&"
export * from "./script.js?vue&type=script&lang=js&"
import style0 from "./index.scss?vue&type=style&index=0&id=516fb150&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "516fb150",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PhoneCallBox: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/small-components/phoneCallBox/index.vue').default,WebReservationBox: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/small-components/webReservationBox/index.vue').default,DownloadText: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/small-components/downloadText/index.vue').default,AppStoreButton: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/small-components/appStoreButton/index.vue').default,QrBox: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/small-components/qrBox/index.vue').default,TimeTable: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/small-components/timeTable/index.vue').default})
