import * as CONS from '~/constants';

export default {
  name: 'consultation-box',
  components: {},
  props: {
    isLocalPlace: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      CONS,
    };
  },
  computed: {},
  methods: {},
};
